import React, { Fragment, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import BannerSection from '../containers/Portfolio/Banner';
import PortfolioShowcase from '../containers/Portfolio/PortfolioShowcase';
import TestimonialSection from '../containers/Portfolio/Testimonial';
import ProcessSection from '../containers/Portfolio/Process';
import SkillSection from '../containers/Portfolio/Skill';
import CallToAction from '../containers/Portfolio/CallToAction';
import ClientsSection from '../containers/Portfolio/Clients';
import ContactSection from '../containers/Portfolio/Contact';
import Seo from '../components/seo';
import Footer from '../containers/Portfolio/Footer';

const Index = () => {
  useEffect(() => {
    let index = window.location.href.indexOf('#');
    if (index !== -1) {
      var element = document.getElementById(
        `${window.location.href.slice(index + 1)}`
      );

      element &&
        element.scrollIntoView(true, {
          behavior: 'auto',
          block: 'end',
          inline: 'start',
        });
    }
  }, []);

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo title="Almog Development Studio - Bring ideas to life. | FlutterDev" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <PortfolioShowcase />
          <TestimonialSection />
          <ProcessSection />
          <SkillSection />
          <CallToAction />
          <ClientsSection />
          <ContactSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
