import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import BannerWrapper from './banner.style';
import { cornerDownRight } from 'react-icons-kit/feather/cornerDownRight';
import JellyfishImage from 'common/src/assets/images/portfolio/jellyfish.png';
import JellyfishWepPImage from 'common/src/assets/images/portfolio/jellyfish.webp';

const BannerSection = ({
  row,
  contentArea,
  imageArea,
  nameStyle,
  designationStyle,
  roleStyle,
  roleWrapper,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...contentArea}>
            <Heading content="Bringing ideas to life." {...nameStyle} />
            <Heading content="Almog Development Studio" {...designationStyle} />
            <Box {...roleWrapper}>
              <Icon
                icon={cornerDownRight}
                style={{ color: '#32ABDF' }}
                size={22}
              />
              <Heading
                content="Good ideas are common – what’s uncommon are people who’ll work hard enough to bring them about. Ashleigh Brilliant"
                {...roleStyle}
              />
            </Box>
          </Box>
          <Box {...imageArea} className="image_area">
            <picture>
              <source
                srcSet={JellyfishWepPImage}
                type="image/webp"
                alt="Jellyfish Almog Development Studio"
              />
              <Image
                src={JellyfishImage}
                alt="Jellyfish Almog Development Studio"
                width="958"
                height="1032"
              />
            </picture>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleStyle: PropTypes.object,
  roleWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  contentArea: {
    width: ['100%', '100%', '50%', '40%'],
    p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imageArea: {
    width: ['100%', '100%', '50%', '60%'],
    flexBox: true,
    alignItems: 'flex-end',
  },
  greetingStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['18px', '18px', '18px', '20px', '30px'],
    fontWeight: '500',
    mb: '8px',
  },
  nameStyle: {
    as: 'h2',
    color: '#fff',
    fontSize: ['38px', '38px', '44px', '60px', '80px'],
    fontWeight: '800',
    mb: '6px',
  },
  designationStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['18px', '18px', '18px', '20px', '30px'],
    fontWeight: '700',
    mb: ['30px', '30px', '25px', '30px', '30px'],
  },
  roleWrapper: {
    flexBox: true,
    mb: '28px',
  },
  roleStyle: {
    as: 'h4',
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '500',
    color: '#fff',
    mb: '0',
    ml: '10px',
  },
  aboutStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '50px',
  },
};

export default BannerSection;
