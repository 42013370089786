import styled from 'styled-components';

export const SkillItem = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0.521px 2.954px 50px 0px rgba(101, 106, 160, 0.1);
`;

export const SkillDetails = styled.div`
  padding: 55px 40px 55px 40px;
  display: flex;
  align-items: center;
  @media (max-width: 1199px) {
    padding: 70px 45px 40px 45px;
  }
  @media (max-width: 990px) {
    padding: 20px 15px 20px 15px;
  }
  @media (max-width: 575px) {
    padding: 35px 15px 20px 15px;
  }
`;

export const SkillIcon = styled.div`
  flex: 0 0 130px;
  max-width: 130px;
  @media (max-width: 990px) {
    flex: 0 0 75px;
    max-width: 75px;
    margin-right: 10px;
  }
  @media (max-width: 575px) {
    flex: 0 0 65px;
    max-width: 65px;
    margin-right: 5px;
  }
`;

export const SkillAbout = styled.div`
  margin-left: 30px;
  @media (max-width: 990px) {
    margin-left: 10px;
  }
`;
