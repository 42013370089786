import React from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';

import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import ProcessItem from './process.style';

const ProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle,
  learningRow,
  learningContentArea,
  learningListArea,
  learningListImageArea,
  learningTitle,
  learningImageTitle,
  learningSubTitle,
  learningDescription,
  buttonWrapper,
  buttonLabelStyle,
  buttonStyle,
  learningList,
  listItem,
  listText,
  listTitle,
  serviceListImageStyle,
  imageTitle,
  imageListTitle,
  processRowImages,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PROCESS_STEPS {
          title
          description
          image {
            publicURL
          }
        }
        SKILLS_IMAGES {
          title
          image {
            publicURL
          }
          imagewebp {
            publicURL
          }
        }
        SERVICE_LIST {
          title
          listItems {
            content
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="process-section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading
            {...secTitle}
            content="From Agile Development to Expert Communications"
          />
          <Text
            {...secDescription}
            content="Great designer-developers are a rare breed – part artist, part coder, part consultant. That’s me! My background in user experience and development enables me to create pixel-perfect experiences."
          />
        </Box>

        <Box {...processRow}>
          {Data.portfolioJson.PROCESS_STEPS.map((item, index) => (
            <Box
              {...processCol}
              key={`process-item-${index}`}
              className="process_item_col"
            >
              <ProcessItem className="process_item">
                <Image
                  src={item.image.publicURL}
                  alt={`process-image-${index + 1}`}
                  {...processImageStyle}
                />
                <Heading as="h3" content={item.title} {...processTitleStyle} />
                <Text content={item.description} {...processDescriptionStyle} />
              </ProcessItem>
            </Box>
          ))}
        </Box>

        <Box {...learningRow}>
          <Box {...learningContentArea}>
            <Heading
              content="Your Expert, Which is why I Never Stop Learning."
              {...learningTitle}
            />
            <Text
              content="I believe that I succeed when my clients succeed."
              {...learningSubTitle}
            />
            <Text
              {...learningDescription}
              content="Hi, I'm Almog, a well-experienced software engineer providing custom software development services, proficient in a wide range of development solutions. I believe simplicity combined with an innovative custom approach to each project is the key to a successful experience."
            />
            <Text
              {...learningDescription}
              content="While over the last few years, I've worked on product solutions for a range of startups, in a variety of industries. I specialize in crafting modern-based platforms and mobile applications that are fast, flexible, real-time, and provide significant benefits for your customers."
            />
            <Text
              {...learningDescription}
              content="Sine 2018, Google Flutter's first stable version, I have been focusing on mobile, desktop, smart screens, and IoT application development using Google Flutter. I consider myself a #FlutterDev."
            />
            <Box {...buttonWrapper}>
              <Text content="Start Your Project ?" {...buttonLabelStyle} />
              <ButtonWrapper>
                <Obfuscate email="hello@almog.io">
                  <Button
                    title="hello@almog.io"
                    className="portfolio_button"
                    {...buttonStyle}
                  />
                </Obfuscate>
              </ButtonWrapper>
            </Box>
          </Box>
          {/* NEW SECTION TEST */}
          <Box {...learningListImageArea}>
            <Box {...processRowImages}>
              <Heading
                content="Technologies that I work with."
                {...learningImageTitle}
              />
            </Box>
            <Box {...processRowImages}>
              {Data.portfolioJson.SKILLS_IMAGES.map((item, index) => (
                <Box {...serviceListImageStyle} key={index}>
                  <picture>
                    <source
                      srcSet={item.imagewebp.publicURL}
                      type="image/webp"
                      alt={`process-image-${index + 1}`}
                    />
                    <Image
                      src={item.image.publicURL}
                      alt={`process-image-${index + 1}`}
                    />
                    <Text content={item.title} {...imageTitle} />
                  </picture>

                  {/*<Image
                    src={item.image.publicURL}
                    alt={`process-image-${index + 1}`}
                  />
                  <Text content={item.title} {...imageTitle} />*/}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

ProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
  learningRow: PropTypes.object,
  learningContentArea: PropTypes.object,
  learningListArea: PropTypes.object,
  learningListImageArea: PropTypes.object,
  learningTitle: PropTypes.object,
  learningImageTitle: PropTypes.object,
  learningSubTitle: PropTypes.object,
  learningDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
  buttonLabelStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  learningList: PropTypes.object,
  listItem: PropTypes.object,
  listText: PropTypes.object,
  listTitle: PropTypes.object,
  serviceListImageStyle: PropTypes.object,
  imageTitle: PropTypes.object,
  imageListTitle: PropTypes.object,
  processRowImages: PropTypes.object,
};

ProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '140px'],
    pb: ['10px', '40px', '30px', '50px', '0'],
  },
  secTitleWrapper: {
    mb: ['60px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-30px', '-70px', '-70px'],
    mr: ['0', '-15px', '-30px', '-70px', '-70px'],
  },
  processRowImages: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '30px', '70px', '70px'],
    pr: ['0', '15px', '30px', '70px', '70px'],
    mb: '40px',
  },
  processImageStyle: {
    ml: 'auto',
    mr: 'auto',
    mb: '35px',
  },
  serviceListImageStyle: {
    width: '100px',
    heigh: '100px',
    pt: ['15px'],
    pr: ['10px'],
    mr: ['15px'],
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    textAlign: 'center',
    mb: ['20px', '20px', '27px', '27px', '27px'],
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  learningRow: {
    flexBox: true,
    flexWrap: 'wrap',
    mt: ['20px', '30px', '70px', '80px', '110px'],
  },
  learningContentArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    pr: ['0px', '0px', '60px', '80px', '110px'],
    mb: ['70px', '70px', '0', '0', '0'],
  },
  learningTitle: {
    fontSize: ['22px', '22px', '24px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['20px', '20px', '15px', '20px', '20px'],
    pr: ['0', '0', '0', '65px', '65px'],
  },
  learningImageTitle: {
    fontSize: ['22px', '22px', '24px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
  },
  learningSubTitle: {
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '20px',
    pr: ['0', '0', '0', '65px', '0'],
  },
  learningDescription: {
    fontSize: '16px',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '25px',
  },
  buttonWrapper: {
    flexBox: true,
    alignItems: 'center',
    mt: ['30px', '40px', '40px', '80px', '80px'],
    flexWrap: ['wrap'],
  },
  buttonLabelStyle: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#32ABDF',
    mb: ['20px', '20px', '20px', '0', '0'],
    mr: '30px',
    width: ['100%', '100%', '100%', 'auto', 'auto'],
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  learningListArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  learningListImageArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    flexWrap: 'wrap',
    mb: ['60px', '140px'],
  },
  learningList: {
    width: ['100%', '33.3333333%', '50%', '50%', '50%'],
    pl: ['0', '0', '35px', '35px', '35x'],
    pr: ['0', '30px', '0', '0', '0'],
    mb: ['40px', '40px', '60px', '80px', '20px'],
  },
  listTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#302b4e',
    mb: '25px',
  },
  imageListTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#302b4e',
    mb: ['5px', '5px', '5px', '5px', '5px'],
    textAlign: 'center',
  },
  imageTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#302b4e',
    mb: '1px',
    mt: '5px',
    textAlign: 'center',
  },
  listItem: {
    flexBox: true,
    alignItems: 'center',
    color: '#43414e',
    mb: '16px',
  },
  listText: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#43414e',
    mb: '0',
    ml: '5px',
  },
};

export default ProcessSection;
