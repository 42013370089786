import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  PortfolioMeta,
  MetaItem,
  InnerPortfolioShowcaseWrapper,
} from './portfolioShowcase.style';
import { PrevButton, NextButton } from '../portfolio.style';
import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import Button from 'common/src/components/Button';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import YouTubePopup from '../../../../../landing-gatsby/src/components/YouTubePopup';
import { SwiperSlide } from 'swiper/react';

const PortfolioShowcase = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  const Data = useStaticQuery(graphql`
    {
      portfolioJson {
        PORTFOLIO_SHOWCASE {
          title
          portfolioItem {
            caseStudy
            title
            description
            link
            services
            techStack
            language
            client
            clientLink
            iosLink
            androidLink
            videoLink
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  `);

  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    animationDuration: 900,
    startAt:
      typeof window !== 'undefined'
        ? parseInt(window.location.search.replace('?', '')) || 0
        : 0,
  };

  const [isVideoOpen, setOpenVideo] = useState(false);

  return (
    <>
      {isVideoOpen && (
        <YouTubePopup
          videoLink={isVideoOpen}
          isVideoOpen={isVideoOpen}
          setOpenVideo={setOpenVideo}
        />
      )}
      <Box {...sectionWrapper} as="section">
        <Container noGutter mobileGutter width="1200px">
          <Box {...secTitleWrapper}>
            <Heading {...secTitle} content="From Idea to Production" />
            <Text
              {...secDescription}
              content="I'm an experienced Sr. full-stack software engineer with 13+ years of experience. I love creating engaging and usable applications and believe simplicity combined with an innovative custom approach to each project is the key to a successful experience. Check out some of my client projects."
            />
          </Box>
          <PortfolioShowcaseWrapper
            id="portfolio-section"
            style={{ paddingTop: '15px' }}
          >
            <Tabs
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent animated={false} />}
            >
              {Data.portfolioJson.PORTFOLIO_SHOWCASE.map((tabItem, index) => {
                let CheckObjectNull = [];
                tabItem.portfolioItem.forEach((caseObject) => {
                  for (const key in caseObject) {
                    CheckObjectNull.push(caseObject[key]);
                  }
                });

                let isEveryCaseNull = CheckObjectNull.every((currentValue) => {
                  return currentValue === null;
                });

                if (isEveryCaseNull) {
                  return null; // return if all values of portfolio are null
                }
                return (
                  <TabPane
                    key={`tabpane-${index}`}
                    tab={
                      <Text
                        key={`TabPane-text-${index}`}
                        content={tabItem.title}
                        data-text={tabItem.title}
                        as="span"
                      />
                    }
                  >
                    <GlideCarousel
                      key={`GlideCarousel-${index}`}
                      carouselSelector={`portfolio-showcase-carousel-${
                        index + 1
                      }`}
                      options={carouselOptions}
                      prevButton={
                        <PrevButton>
                          <span />
                        </PrevButton>
                      }
                      nextButton={
                        <NextButton>
                          <span />
                        </NextButton>
                      }
                    >
                      <>
                        {tabItem.portfolioItem.map((portfolioItem, index) => {
                          let CheckObjectNull = [];
                          for (const key in portfolioItem) {
                            CheckObjectNull.push(portfolioItem[key]);
                          }
                          let allnullPorperties = CheckObjectNull.every(
                            (currentValue, index, arr) => {
                              return currentValue === null;
                            }
                          );
                          return (
                            <span key={`return-${index}`}>
                              {!allnullPorperties && (
                                <SwiperSlide key={`GlideSlide2-${index}`}>
                                  <PortfolioShowcaseItem
                                    key={`PortfolioShowcaseItem-${index}`}
                                  >
                                    <InnerPortfolioShowcaseWrapper>
                                      <Box
                                        {...portfolioImage}
                                        key={`boxportfolioImage-${index}`}
                                      >
                                        <GatsbyImage
                                          key={`image-${index}`}
                                          image={
                                            (portfolioItem.image !== null) |
                                            undefined
                                              ? portfolioItem.image
                                                  .childImageSharp.gatsbyImageData
                                              : {}
                                          }
                                          alt={`PortfolioImage-${index + 1}`}
                                        />
                                      </Box>
                                      <Box
                                        {...portfolioDetails}
                                        key={`box2-${index}`}
                                      >
                                        <PortfolioLink
                                          key={`PortfolioLink-${index}`}
                                        >
                                          {!!portfolioItem.videoLink && (
                                            <span
                                              style={{ cursor: 'pointer' }}
                                              role="button"
                                              tabIndex={0}
                                              key={`a1-${index}`}
                                              className="link-style"
                                              onKeyDown={(e) =>
                                                setOpenVideo(
                                                  portfolioItem.videoLink
                                                )
                                              }
                                              onClick={(e) =>
                                                setOpenVideo(
                                                  portfolioItem.videoLink
                                                )
                                              }
                                            >
                                              YouTube Video
                                            </span>
                                          )}
                                          {!!portfolioItem.androidLink &&
                                            !!portfolioItem.videoLink && (
                                              <span key={`span1-${index}`}>
                                                {'  '}|{'  '}
                                              </span>
                                            )}
                                          {tabItem.title === 'MOBILE' && (
                                            <span key={`span2-${index}`}>
                                              {!!portfolioItem.androidLink && (
                                                <span>
                                                  <a
                                                    key={`a2-${index}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={
                                                      portfolioItem.androidLink ||
                                                      ''
                                                    }
                                                  >
                                                    Google Play
                                                  </a>
                                                  {'  '}|{'  '}
                                                </span>
                                              )}
                                              {!!portfolioItem.iosLink && (
                                                <span key={`span3-${index}`}>
                                                  {' '}
                                                  <a
                                                    key={`a3-${index}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={
                                                      portfolioItem.iosLink ||
                                                      ''
                                                    }
                                                  >
                                                    iTunes Store
                                                  </a>
                                                </span>
                                              )}
                                            </span>
                                          )}

                                          {!!portfolioItem.link &&
                                            !!portfolioItem.videoLink && (
                                              <span key={`span4-${index}`}>
                                                {'  '}|{'  '}
                                              </span>
                                            )}

                                          {!!portfolioItem.link && (
                                            <a
                                              key={`a4-${index}`}
                                              target="_blank"
                                              rel="noreferrer"
                                              href={portfolioItem.link || ''}
                                            >
                                              Website
                                            </a>
                                          )}
                                        </PortfolioLink>

                                        <Heading
                                          key={`Heading-${index}`}
                                          content={portfolioItem.title}
                                          {...titleStyle}
                                        />
                                        <Text
                                          key={`Heading-text-${index}`}
                                          content={portfolioItem.description}
                                          {...detailsStyle}
                                        />

                                        <div
                                          key={`div-button-${index}`}
                                          style={{ textAlign: 'right' }}
                                        >
                                          {portfolioItem.caseStudy ===
                                            'true' && (
                                            <ButtonWrapper
                                              key={`ButtonWrapper-${index}`}
                                              style={{
                                                textAlign: 'center',
                                                paddingRight: '25px',
                                              }}
                                            >
                                              <AniLink
                                                key={`aCase-${index}`}
                                                to={`/portfolio/case-study/${portfolioItem.title
                                                  .toLowerCase()
                                                  .replace(/[^\w ]+/g, '')
                                                  .replace(/ +/g, '-')}`}
                                                paintDrip
                                                hex="#56AADA"
                                              >
                                                <Button
                                                  key={`button-case-${index}`}
                                                  title="Case Study"
                                                  className="portfolio_button"
                                                />
                                              </AniLink>
                                            </ButtonWrapper>
                                          )}
                                        </div>
                                      </Box>
                                    </InnerPortfolioShowcaseWrapper>
                                    {portfolioItem.client ||
                                    portfolioItem.services ||
                                    portfolioItem.techStack ||
                                    portfolioItem.clientLink ||
                                    portfolioItem.language ? (
                                      <PortfolioMeta
                                        key={`PortfolioMeta-${index}`}
                                      >
                                        {portfolioItem.client ? (
                                          <MetaItem
                                            key={`MetaItem-${index}`}
                                            className="meta_featured"
                                          >
                                            Client:
                                            {portfolioItem.clientLink ? (
                                              <a
                                                key={`aclient-${index}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                href={
                                                  portfolioItem.clientLink ||
                                                  '#'
                                                }
                                              >
                                                <b key={`bclient-${index}`}>
                                                  {portfolioItem.client}
                                                </b>{' '}
                                              </a>
                                            ) : (
                                              <b key={`b2client-${index}`}>
                                                &nbsp;{portfolioItem.client}
                                              </b>
                                            )}
                                          </MetaItem>
                                        ) : (
                                          ''
                                        )}
                                        {portfolioItem.services ? (
                                          <MetaItem
                                            key={`MetaItem-services-${index}`}
                                          >
                                            Services:&nbsp;
                                            <b
                                              key={`MetaItem-services-b-${index}`}
                                            >
                                              {portfolioItem.services}
                                            </b>
                                          </MetaItem>
                                        ) : (
                                          ''
                                        )}
                                        {portfolioItem.techStack ? (
                                          <MetaItem
                                            key={`MetaItem-tech-${index}`}
                                          >
                                            Tech Stack:&nbsp;
                                            <b key={`MetaItem-tech-b-${index}`}>
                                              {portfolioItem.techStack}
                                            </b>
                                          </MetaItem>
                                        ) : (
                                          ''
                                        )}
                                        {portfolioItem.language ? (
                                          <MetaItem
                                            key={`MetaItem-lan-${index}`}
                                          >
                                            Language:&nbsp;
                                            <b key={`MetaItem-lang-b-${index}`}>
                                              {portfolioItem.language}
                                            </b>
                                          </MetaItem>
                                        ) : (
                                          ''
                                        )}
                                      </PortfolioMeta>
                                    ) : (
                                      ''
                                    )}
                                  </PortfolioShowcaseItem>
                                </SwiperSlide>
                              )}
                            </span>
                          );
                        })}
                      </>
                    </GlideCarousel>
                  </TabPane>
                );
              })}
            </Tabs>
          </PortfolioShowcaseWrapper>
        </Container>
      </Box>
    </>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '150px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default PortfolioShowcase;
